/*
actual prod
export const environment = {
    production: true,
    api_url: 'https://service.demo.recykal.com',
    logging: {},
    codePushKey: 'lrd4CrnHaNm_S9t6x4sxvfR_I3btByWDHh5p4',
    google: {
        web_client_id: '133348576069-uc91hdha5894f77ksjk5dt5nsc8vt36r.apps.googleusercontent.com'
    },
    appShareUrl: 'https://play.google.com/store/apps/details?id=com.rapidue.recykal'
    forgotPasswordEndPoint: 'http://recykal.com/reset-password.html',
};
*/

export const environment = {
    production: true,
    api_url: 'https://service.demo.recykal.com',
    logging: {
        logLevels: [
            {
                loggerName: 'root',
                logLevel: 'INFO'
            }
        ]
    },
    codePush: {
        keys: {
            android: 'lrd4CrnHaNm_S9t6x4sxvfR_I3btByWDHh5p4',
            ios: 'guHhWGcd5EuWff-grd89mbDtvZbdOk5ENaMW2'
        }
    },
    google: {
        web_client_id: '1070139480218-1n6r91da7iru254pes7b6kga25aa3ioj.apps.googleusercontent.com'
    },
    analytics: {
        firebase: true,
        facebook: true
    },
    chat: {
        app_id: '99a61bd9-ce4e-4ca5-956c-50ae5257e665',
        app_token: '1894582c-b705-4451-8687-5f6237daf85c'
    },
    appShareUrl: 'https://rcyl.page.link/invite',
    forgotPasswordEndPoint: 'https://dashboard.recykal.com/#/auth/forgot-password',
    externalUrl: {
        tnc: 'https://admin.marketplace.recykal.com/terms_of_use_v2.html',
        privacy_policy: 'https://admin.marketplace.recykal.com/privacy-policy.html'
    },
    market: {
        categoryIds: ['PLA101', 'PEP201', 'CO40101'],
        max_imgs_listing: 4,
        max_imgs_order: 6,
        max_imgs_seller_weigh_bridge: 4,
        max_imgs_order_lab_report: 4
    },
    SHS: {
        partnerId: 33
    }
};
